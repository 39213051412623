
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';

@Component({
  components: {
    DefaultLoader: () => getComponent('loaders/DefaultLoader'),
  },
})
export default class SkeletonLoader extends Vue {
  @Prop({ type: Boolean, default: true }) loading!: boolean;

  @Prop({ type: Number, default: 1 }) repeat!: number;

  @Prop({ type: String, default: 'default' }) loaderType!: string;
}
